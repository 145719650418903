import React from 'react'
import Link from 'next/link'
import { BaseImage } from 'components/common/Image'

const PageError = () => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <div className="mx-4 mb-20 flex flex-col items-center self-stretch rounded-xl bg-white px-12 py-20 lg:mx-0 lg:w-500 lg:self-center lg:px-24">
        <BaseImage src="/icons/colored/not_found.svg" className="pointer-events-none h-auto w-48" alt="职位模块正在开发者" />
        <h5 className="mt-4 text-sm text-gray-400">内容不存在</h5>
        <Link href="/">
          <button className="button-normal mt-8 h-10 w-40 rounded text-sm">返回首页</button>
        </Link>
      </div>
    </div>
  )
}

PageError.options = {
  title: '出错啦',
  layoutClassName: 'bg-gray-100 h-screen',
  ssr: true,
  withFooter: true,
}

export default PageError
